import styled from '@emotion/styled'

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 12px;
  }

  .rdp {
    margin: 0;
  }

  .rdp-caption {
    color: ${({ theme }) => theme.colors.primary.base.hex};
    border-bottom: 1px solid #bebebe;
  }

  .rdp-month {
    background-color: white;
    box-shadow: 1px 1px 10px 2px #dcdcdc;
    border-radius: 10px;
    padding: 10px;
  }

  .rdp-head_cell {
    opacity: 0.54;
  }

  .rdp-cell {
    &:first-of-type,
    &:last-of-type {
      opacity: 0.54;
    }
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    background-color: #f5f5f5;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: ${({ theme }) => theme.colors.primary.base.hex};
    color: #ffffff;
  }

  .rdp-day_today {
    border: 1px solid ${({ theme }) => theme.colors.primary.base.hex};
  }
`

export default DatePickerWrapper
