import React from 'react'

import { Typography, useMapi } from '@leshen/gatsby-theme-leshen'
import { Form } from '@leshen/ui'

import FormContainer from './LeadForm.styles'

const { EmilyClient } = require('@clearlink/emily')

const ScheduleLeadForm = ({
  selectedDay,
  selectedTime,
  setIsSubmitted,
  setShowSpinner,
  setIsFlexContainerVisible,
  setIsThankYouVisible,
  executeScroll,
  setIsErrorVisible,
}) => {
  const { requestId, sessionPromoCode } = useMapi()

  return (
    <FormContainer>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        Provide your information
      </Typography>
      <Form
        submitButtonText="Submit Call Request"
        submitButtonColor="primary"
        formName="med-lead-form-scheduler"
        onSubmit={async ({ values }) => {
          const mergedValues = {
            ...values,
            brand: 'AET',
            elqFormName: 'med-lead-form-scheduler',
            origin: 'https://www.aetnamedicaredirect.com',
            domain: 'https://www.aetnamedicaredirect.com',
            promo_code: sessionPromoCode || 88487,
            request_id: requestId,
            schedule_date: selectedDay,
            schedule_time: selectedTime,
            source: 'med-lead-form-scheduler',
          }

          try {
            // dev url: https://leads.dev.aws.clear.link
            const emilyClient = new EmilyClient('https://leads.clear.link')
            // const emilyClient = new EmilyClient(
            //   'https://leads.dev.aws.clear.link'
            // )

            setIsFlexContainerVisible(false)

            const spinnerPromise = new Promise((resolve) =>
              setTimeout(() => {
                setShowSpinner(true)
                resolve()
              }, 300)
            )

            const minimumSpinnerDisplayTime = new Promise((resolve) =>
              setTimeout(resolve, 3000)
            ) // Ensure spinner shows for at least 3 seconds

            await Promise.all([
              spinnerPromise,
              emilyClient.submit(mergedValues),
              minimumSpinnerDisplayTime,
              // using _ to ignore the first value from the array destructuring
              // eslint-disable-next-line no-unused-vars
            ]).then(([_, response]) => {
              if (response.status === 200) {
                setShowSpinner(false)
                setIsThankYouVisible(true)
                setIsSubmitted(true)
                setIsErrorVisible(false)
                executeScroll()
              } else {
                setShowSpinner(false)
                setIsFlexContainerVisible(true)
                setIsThankYouVisible(false)
                setIsSubmitted(false)
                executeScroll()
              }
            })
          } catch (error) {
            console.error('Submission error:', error) // eslint-disable-line no-console
            setIsFlexContainerVisible(true)
            setIsThankYouVisible(false)
            setIsErrorVisible(true)
            setIsSubmitted(false)
            setShowSpinner(false)
            executeScroll()
          }
        }}
        emilyTCPAUpdate={() => {}}
        fields={[
          {
            name: 'first_name',
            type: 'text',
            label: 'First Name',
            required: true,
          },
          {
            name: 'last_name',
            type: 'text',
            label: 'Last Name',
            required: true,
          },
          {
            name: 'phone',
            type: 'tel',
            label: 'Phone Number',
            required: true,
          },
          {
            name: 'zip_postal',
            type: 'text',
            label: 'Zip Code',
            required: true,
          },
        ]}
        isMultiStep={false}
        disclaimer={null}
        initialStepValues={{}}
        smartyStreetsWebsiteKey={null}
        addressQualificationCode={null}
        spanish={false}
        requestId={null}
      />
    </FormContainer>
  )
}

export default ScheduleLeadForm
