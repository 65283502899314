import React, { useState, useEffect } from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { Typography } from '@leshen/gatsby-theme-leshen'
import DatePickerWrapper from './DatePicker.styles'

const FormScheduler = ({ setSelectedDayFromParent }) => {
  const [selectedDay, setSelectedDay] = useState(new Date())
  const disableWeekends = { daysOfWeek: [0, 6] }
  const [footer, setFooter] = useState(
    <p>Please select a date for your appointment.</p>
  )

  useEffect(() => {
    setSelectedDayFromParent(selectedDay)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDayClick = (day) => {
    try {
      if (!day || !(day instanceof Date) || Number.isNaN(day.getTime())) {
        return
      }

      if (day.getDay() !== 0 && day.getDay() !== 6) {
        setSelectedDay(day)
        setSelectedDayFromParent(day)
        setFooter('')
      } else {
        setFooter(
          <>
            <p style={{ fontSize: '12px' }}>
              Weekends are unavailable. Please select a weekday.
            </p>
          </>
        )
      }
    } catch (error) {
      console.error('An error occurred:', error) // eslint-disable-line no-console
      setFooter(
        <p>Something went wrong. Please try again or call for assistance.</p>
      )
    }
  }

  return (
    <DatePickerWrapper>
      <Typography variant="h5">Choose a date that works for you</Typography>
      <DayPicker
        fromDate={new Date()}
        mode="single"
        showOutsideDays
        disabled={disableWeekends}
        selected={selectedDay}
        onSelect={handleDayClick}
        footer={footer}
      />
    </DatePickerWrapper>
  )
}

export default FormScheduler
