/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import womanSchedulingAppointment from '../images/heroes/woman-scheduling-appointment.jpg'
import DatePickerWithLeadForm from '../components/DatePickerWithLeadForm'

import {
  Hero,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            ' Schedule a Call with Aetna Medicare Direct | Call 855-200-5690 ',
          description:
            'Schedule a call with an Aetna Medicare Direct licensed agent to get help choosing the right plan. Call 855-200-5690',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/schedule',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      ' Schedule a Call with Aetna Medicare Direct | Call 855-200-5690 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={womanSchedulingAppointment}
                  alt="elderly woman talks on mobile phone and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Schedule Your Medicare Consultation{' '}
                  </Typography>

                  <Typography variant="h4">
                    Let us help you enroll in the right Aetna Medicare plan.{' '}
                  </Typography>
                </>
              }
            />
          </div>
        </div>
        <DatePickerWithLeadForm />
        <VariableContent style={{ paddingTop: '0px' }} />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
