import React, { useState, useEffect, useRef } from 'react'
import { format } from 'date-fns'
import { Typography } from '@leshen/gatsby-theme-leshen'
import {
  TimeSelectorWrapper,
  SelectInput,
  SelectWrapper,
  StyledArrow,
} from './TimeSelector.styles'

const TimeSelector = ({ selectedDay, setSelectedTime }) => {
  const [availableTimes, setAvailableTimes] = useState([])
  const selectRef = useRef(null)

  useEffect(() => {
    const currentTime = new Date()
    const currentDay = currentTime.getDate()
    const currentMonth = currentTime.getMonth()
    const currentYear = currentTime.getFullYear()
    let currentTimeMinutes =
      currentTime.getHours() * 60 + currentTime.getMinutes()

    const isSelectedDayToday =
      selectedDay &&
      selectedDay.getDate() === currentDay &&
      selectedDay.getMonth() === currentMonth &&
      selectedDay.getFullYear() === currentYear

    if (!isSelectedDayToday) {
      currentTimeMinutes = 0
    }

    const times = []
    for (let i = 8; i <= 17; i += 1) {
      ;[0, 30].forEach((minute) => {
        const paddedMinute = String(minute).padStart(2, '0')
        const hour = i
        const totalMinutes = hour * 60 + minute

        if (totalMinutes >= currentTimeMinutes) {
          const displayHour = hour > 12 ? hour - 12 : hour
          const ampm = hour < 12 ? 'AM' : 'PM'
          const timeString = `${displayHour}:${paddedMinute} ${ampm} MST`
          times.push(timeString)
        }
      })
    }
    setAvailableTimes(times)
    setSelectedTime(times[0])

    const [firstTime] = times
    setAvailableTimes(times)
    setSelectedTime(firstTime)

    // Update the value of the select element to force the UI to update
    if (selectRef.current) {
      selectRef.current.value = firstTime
    }
  }, [selectedDay, setSelectedTime])

  return (
    <TimeSelectorWrapper>
      <Typography variant="h5">
        Select a time that fits your schedule
      </Typography>
      <SelectWrapper>
        <SelectInput
          ref={selectRef}
          onChange={(e) => setSelectedTime(e.target.value)}
        >
          <option value="" disabled>
            -- Please Select --
          </option>
          {availableTimes.map((time) => (
            <option key={time} value={time}>
              {time}
            </option>
          ))}
        </SelectInput>
        <StyledArrow />
      </SelectWrapper>
      <Typography variant="body">
        Available weekdays between
        <br /> 10:30am-7pm EST / 8:30am-5pm MST
      </Typography>
      {availableTimes.length === 0 && (
        <p>No available times on {selectedDay && format(selectedDay, 'PP')}.</p>
      )}
    </TimeSelectorWrapper>
  )
}

export default TimeSelector
