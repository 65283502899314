import styled from '@emotion/styled'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 12px;
  }
  /* Override existing styles */
  .med-lead-form-scheduler label {
    display: block;
    position: relative;
    grid-area: initial;
    padding-bottom: 0;

    span {
      color: ${(props) => props.theme.colors.primary.base.hex};
      font-weight: 700;
      font-size: 16px;
    }

    .error-text {
      bottom: 44px;
      right: 0;
      left: unset;
      color: ${(props) => props.theme.colors.error.base.hex};
      font-size: 12px;
    }
  }

  /* Mobile view */
  @media (max-width: 767px) {
    .med-lead-form-scheduler label {
      margin-bottom: 20px;
    }

    .label.input-label {
      display: block;
      text-align: left;
      margin-bottom: 4px;
      margin-left: 4px;
    }
  }

  /* Desktop and Tablet view */
  @media (min-width: 768px) {
    .med-lead-form-scheduler {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;

      label {
        grid-row: auto;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .submit-button {
      grid-column: 1 / -1; /* Span across both columns */
      grid-row: 3; /* Explicitly set it to the third row */
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* Styles for input and buttons */
  .leshen-input-text,
  .leshen-input-tel {
    width: 100%;
    padding: 10px;
  }

  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: ${(props) => props.theme.colors.primary.base.hex};
    color: white;
    border: none;
  }

  .leshen-input-text,
  .leshen-input-tel {
    box-shadow: 1px 1px 10px 2px #dcdcdc;
    border: 0;
  }
`

export default FormContainer
