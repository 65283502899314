import styled from '@emotion/styled'
import { KeyboardArrowDown } from 'emotion-icons/material'

export const TimeSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  * {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    gap: 12px;
  }
`

export const SelectWrapper = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.primary.base.hex};
  background: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.primary.base.hex},
    ${({ theme }) => theme.colors.primary.base.hex} 50px,
    transparent 50px,
    transparent
  );
  border-radius: 10px;
`

export const SelectInput = styled.select`
  appearance: none;
  background: linear-gradient(
    90deg,
    white calc(100% - 50px),
    ${({ theme }) => theme.colors.primary.base.hex} calc(100% - 50px),
    ${({ theme }) => theme.colors.primary.base.hex} 100%
  );
  width: 100%;
  height: 50px;
  padding-right: 24px;
  padding-left: 12px;
  border: 0;
  box-shadow: 1px 1px 10px 2px #dcdcdc;
  border-radius: 10px;
`

export const StyledArrow = styled(KeyboardArrowDown)`
  color: white;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
`
