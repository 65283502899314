import React, { useState, useRef } from 'react'
import { format } from 'date-fns'

import {
  Typography,
  VariableContent,
  useMapi,
  SplitModule,
  Column,
  Columns,
} from '@leshen/gatsby-theme-leshen'
import scrollTo from '@leshen/ui/src/utils/scrollTo'

import {
  OuterContainer,
  StepContainer,
  TwoFormContainer,
  SingleColumnFormContainer,
  FlexContainer,
  IntroContainer,
} from './DatePickerWithLeadForm.styles'

import Scheduler from './DatePicker'
import TimeSelector from './TimeSelector'
import ScheduleLeadForm from './LeadForm'
import Spinner from '../Spinner'

export default function FormsWithStep() {
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const formattedDate = selectedDay ? format(selectedDay, 'MM/dd/yyyy') : null
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isThankYouVisible, setIsThankYouVisible] = useState(false)
  const [isErrorVisible, setIsErrorVisible] = useState(false)
  const [isFlexContainerVisible, setIsFlexContainerVisible] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const scrollToRef = useRef(null)

  const executeScroll = () => {
    scrollTo('#thank-you')
    switch (true) {
      case isThankYouVisible:
        scrollTo('#thank-you')
        break
      case showSpinner:
        scrollTo('#spinner')
        break
      case isErrorVisible:
        scrollTo('#error')
        break
      default:
        break
    }
  }

  const { rotatedNumber } = useMapi()

  return (
    <>
      {!isSubmitted && !showSpinner && (
        <IntroContainer>
          <Typography variant="h2">
            Ready to talk to a{' '}
            <span className="inline">licensed agent now?</span>
          </Typography>
          <Typography variant="h4">
            Give us a call at{' '}
            <span className="inline">
              <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>
            </span>
          </Typography>
        </IntroContainer>
      )}

      {isFlexContainerVisible && (
        <FlexContainer>
          <OuterContainer>
            <StepContainer>Step 1: </StepContainer>
            <TwoFormContainer>
              <Scheduler setSelectedDayFromParent={setSelectedDay} />
              <TimeSelector
                selectedDay={selectedDay}
                setSelectedTime={setSelectedTime}
              />
            </TwoFormContainer>
          </OuterContainer>
          <OuterContainer>
            <StepContainer>Step 2: </StepContainer>
            <SingleColumnFormContainer>
              <ScheduleLeadForm
                setIsThankYouVisible={setIsThankYouVisible}
                setIsErrorVisible={setIsErrorVisible}
                setIsFlexContainerVisible={setIsFlexContainerVisible}
                selectedDay={formattedDate}
                selectedTime={selectedTime}
                isSubmitted={isSubmitted}
                setShowSpinner={setShowSpinner}
                setIsSubmitted={setIsSubmitted}
                executeScroll={executeScroll}
              />
            </SingleColumnFormContainer>
          </OuterContainer>
        </FlexContainer>
      )}

      {isThankYouVisible && (
        <SplitModule
          id="thank-you"
          alignMainContent="Left"
          mainContent={
            <>
              <Typography variant="h4">
                Appointment Confirmed: <br />
                We Look Forward to Assisting You
              </Typography>
              <Typography variant="p">
                If you&apos;d like to speak with a licensed agent right away,
                you&apos;re welcome to call us at{' '}
                <strong>
                  <a href={`tel:${rotatedNumber}`}>
                    <span className="inline">{rotatedNumber}</span>
                  </a>
                </strong>
              </Typography>
            </>
          }
        >
          <Columns cardStyled>
            <Column cardStyled>
              <Typography variant="h4">Appointment details</Typography>
              <Typography variant="p">
                Date: <strong>{formattedDate}</strong>
              </Typography>
              <Typography variant="p">
                Time: <strong>{selectedTime}</strong>
              </Typography>
            </Column>
          </Columns>
        </SplitModule>
      )}

      {isErrorVisible && (
        <div ref={scrollToRef} className="fade-in" id="error">
          <VariableContent
            style={{
              paddingBottom: '24px',
            }}
            mainContent={
              <>
                {' '}
                <Typography variant="h2">
                  Looks like something went wrong on our end.
                </Typography>
                <Typography variant="h4" style={{ marginBottom: '8px' }}>
                  Please try again, or call us at{' '}
                  <span className="inline">
                    <a href={`tel:${rotatedNumber}`}>{rotatedNumber}</a>
                  </span>
                </Typography>
              </>
            }
          />
        </div>
      )}

      {showSpinner && (
        <VariableContent
          mainContent={<></>}
          alignMainContent="Center"
          id="spinner"
        >
          <Spinner />
        </VariableContent>
      )}
    </>
  )
}
