import styled from '@emotion/styled'

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const IntroContainer = styled.div`
  padding: 32px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    text-align: center;
  }
`

export const OuterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  .leshen-form {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: auto 1fr;
    gap: 8px;
    padding-left: calc(2.2222222222222223vw + 21.333333333333332px);
    padding-right: calc(2.2222222222222223vw + 21.333333333333332px);
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`

export const StepContainer = styled.div`
  text-align: left;
  align-self: flex-start;
  font-size: 26px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.base.hex};

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    margin-left: 32px;
  }
`

const containerStyles = `
display: grid;
gap: 32px;
background-color: #f5f5f5;
padding: 32px;
@media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
  padding: 32px 50px 50px;
}

`

export const TwoFormContainer = styled.div`
  ${containerStyles}
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr;
  }
`

export const SingleColumnFormContainer = styled.div`
  // Styles for the single column layout
  ${containerStyles}
`
